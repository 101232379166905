import React from 'react'
import Button from 'components/Button'
import Link from 'components/Link'

export default function StoryblokButton ({ data }) {
  return (
    <Link
      link={data.link}
      productModalType={data.extendedProductModalLink}
      gtmEvent={data.gtmEvent}
      productModalGroup={data.extendedProductModalGroup}
    >
      <Button id={data.hash}>{data.label}</Button>
    </Link>
  )
}
